import it from './lang/it-IT/index.js'
// import en from './lang/en-US/index.js'

export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    it,
    // en,
  },
}))
